import mixpanel from "mixpanel-browser";
import React from "react";
import { IntercomProvider } from "react-use-intercom";
import { MixpanelContext } from "./src/tracking";

const INTERCOM_APP_ID = "hhm6xlhq";
// const STAGING_INTERCOM_APP_ID = "r6eh0f0u";
const MIXPANEL_KEY = "7337882bb8c0c0bbcddf61637429a466";
// const STAGING_MIXPANEL_KEY = "7337882bb8c0c0bbcddf61637429a466";

export const wrapRootElement = ({ element }) => {
  mixpanel.init(MIXPANEL_KEY);
  if (process.env.NODE_ENV !== "production") {
    mixpanel.disable();
  }

  return (
    <MixpanelContext.Provider value={mixpanel}>
      <IntercomProvider initializeDelay={5000} appId={INTERCOM_APP_ID}>
        {element}
      </IntercomProvider>
    </MixpanelContext.Provider>
  );
};
